<template>
  <div class="container">
    <b-alert
      dismissible
      fade
      scrollable
      :variant="alertVariant"
      :show="dismissCountDown"
      @dismiss-count-down="countDownChanged"
    >
      {{ alertText }}
    </b-alert>
    <b-modal
      id="update-reservation-modal"
      hide-footer
      size="lg"
      :on-enter-key-press="updateReservation"
      :title="title"
      no-close-on-backdrop
      no-close-on-esc
      @hidden="resetInvitedUserObject"
    >
      <div class="d-block text-center"></div>
      <div class="row">
        <div class="col-12 text-left">
          <div class="form-group">
            <label>{{ $t('reservationSubject') }}:</label>
            <input
              v-model="singleReservation.reservationSubject"
              :disabled="disableSubject"
              type="text"
              class="form-control"
              trim
            />
          </div>
        </div>
      </div>
      <div class="row">
        <p class="text-left ml-3">
          {{ $t('guestList') }}
          <b-button variant="secondary" size="sm" @click="addField">
            <font-awesome-icon icon="plus" /> {{ $t('add') }}
          </b-button>
        </p>
        <div class="col-12 text-left">
          <div v-if="invitedUsers.length > 0" class="row">
            <div class="col-5 text-left">
              <div class="form-group mb-0">
                <label>{{ $t('name') }}:</label>
              </div>
            </div>
            <div class="col-5 text-left">
              <div class="form-group mb-0">
                <label>{{ $t('email') }}:</label>
              </div>
            </div>
          </div>
          <div
            v-for="(user, index) in invitedUsers"
            :key="index"
            class="row align-items-center"
          >
            <div class="col-5 text-left">
              <div class="form-group">
                <b-form-input
                  v-model="user.name"
                  type="text"
                  class="form-control"
                  @blur="validateForm"
                  @keyup="validateForm"
                />
              </div>
            </div>
            <div class="col-5 text-left">
              <div class="form-group">
                <input
                  v-model="user.email"
                  type="text"
                  :class="['form-control', isEmailValid(user.email)]"
                  @blur="validateForm"
                  @keyup="validateForm"
                />
              </div>
            </div>
            <div class="col-2 text-left">
              <div class="form-group">
                <span
                  class="text-danger cursor-pointer"
                  @click="removeField(index)"
                >
                  <font-awesome-icon icon="times" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 text-left">
          <b-checkbox
            v-if="allowPrivateReservations"
            v-model="singleReservation.visibleToOthers"
            checked="singleReservation.visibleToOthers"
            :value="true"
            :unchecked-value="false"
          >
            {{ $t('reservationVisibleToOthers') }}
          </b-checkbox>
        </div>
      </div>
      <hr />
      <label style="font-size:20px">{{
        $t('reserveableAssets')
      }}</label>
      &nbsp;
      <span
        v-b-tooltip.hover
        class="tip-icon"
        :title="$t('reserveableAssetsHint')"
      >
        <font-awesome-icon icon="question-circle" />
      </span>

      <b-card
        v-for="(assetType, index) in assetTypes"
        :key="assetType.name"
        no-body
        class="mt-2"
      >
        <b-card-header
          v-if="assetType.available"
          v-b-toggle="'accordion-' + index"
          class="p-1 pl-2"
          :class="['select-attribute']"
          style="text-align:left"
        >
          {{ assetType.name }}
        </b-card-header>
        <b-collapse :id="'accordion-' + index">
          <b-card-body>
            <b-row>
              <div class="col-12 col-lg-2">
                <b-form-group
                  id="fieldset-search"
                  :label="$t('select')"
                  label-for="input-search"
                  label-align="left"
                >
                  <b-form-checkbox
                    :id="assetType.name"
                    v-model="assetType.checked"
                    :checked="assetType.checked"
                    name=""
                    :disabled="!assetType.available"
                  >
                    {{ assetType.name }}
                  </b-form-checkbox>
                </b-form-group>
              </div>
              <div class="col-12 col-lg-5">
                <b-form-group
                  id="fieldset-qty"
                  :label="$t('qty')"
                  label-for="input-qty"
                  label-align="left"
                >
                  <b-form-input
                    id="input-qty"
                    v-model="assetType.qty"
                    :placeholder="$t('qty')"
                    trim
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12 col-lg-5">
                <b-form-group
                  id="fieldset-comment"
                  :label="$t('comment')"
                  label-for="input-comment"
                  label-align="left"
                >
                  <b-form-input
                    id="input-comment"
                    v-model="assetType.comment"
                    :placeholder="$t('comment')"
                    trim
                  ></b-form-input>
                </b-form-group>
              </div>
            </b-row>
          </b-card-body>
        </b-collapse>
      </b-card>
      <template v-if="errors.length">
        <label style="font-size:20px">{{
          $t('changeAssetQty')
        }}</label>
        <div
          v-for="(error, index) in errors"
          :key="index"
          class="row"
        >
          <div class="col-12 text-left">
            <label style="color: red;">{{ error }}</label>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col-12 text-left">
          <b-button
            class="mt-3"
            block
            :disabled="disabled || isBusy"
            @click="updateReservation"
          >
            <b-spinner v-if="isBusy" small></b-spinner>
            {{ $t('update') }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import BootstrapModalHOC from '@/components/hocs/BootstrapModal'
import { emailRegex } from '@/constants'
import { mapGetters, mapState } from 'vuex'
import OkkuApi from '@/services/OkkuApi'
import { get as _get } from 'lodash'

export default {
  components: {
    'b-modal': BootstrapModalHOC
  },
  props: {
    singleReservation: {
      type: Object,
      required: true
    },
    invitedUsers: {
      type: Array,
      required: true
    },
    oldReservation: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      title: 'Update reservation',
      loading: true,
      reservationSubject: '',
      isValidInfo: true,
      dismissCountDown: 0,
      alertVariant: 'success',
      alertText: '',
      disabled: false,
      isBusy: false,
      disableSubject: false,
      assetTypes: [],
      errors: []
    }
  },
  computed: {
    ...mapState('common', {
      isDashboardAdmin: '$isDashboardAdmin',
      keycloakUserProfile: 'keycloakUserProfile'
    }),
    ...mapGetters('common', {
      buildingTimezone: 'buildingTimezone',
      roomReservationSubject: 'allowRoomReservationSubject',
      deskReservationSubject: 'allowDeskReservationSubject',
      enableAllUsersToInviteGuests: 'enableAllUsersToInviteGuests',
      allowPrivateReservations: 'allowPrivateReservations'
    }),
    isAllowedToInviteGuests() {
      return this.enableAllUsersToInviteGuests || this.canInviteGuests
    },
    canInviteGuests() {
      return (
        this.keycloakUserProfile &&
        this.keycloakUserProfile.attributes &&
        _get(
          this.keycloakUserProfile,
          'attributes.isAllowedToInviteGuests',
          false
        )
      )
    }
  },
  watch: {
    singleReservation() {
      if (
        this.singleReservation.objectType === 'DESK' &&
        this.deskReservationSubject
      ) {
        this.disableSubject = false
      } else if (
        this.singleReservation.objectType === 'ROOM' &&
        this.roomReservationSubject
      ) {
        this.disableSubject = false
      } else {
        this.disableSubject = true
      }
      OkkuApi.getAssetTypes(
        this.singleReservation.startDatetime,
        this.singleReservation.endDatetime
      ).then(assetTypes => {
        this.updateAssetTypeChecked(assetTypes)
      })
    }
  },
  mounted() {
    this.disabled = false
    OkkuApi.getAssetTypes(
      this.singleReservation.startDatetime,
      this.singleReservation.endDatetime
    ).then(assetTypes => {
      this.updateAssetTypeChecked(assetTypes)
    })
  },
  methods: {
    updateAssetTypeChecked(assetTypes) {
      // Updates all assets to set whether they should be checked by default and are available
      assetTypes.forEach(assetType => {
        this.singleReservation?.reservationAssets?.forEach(type => {
          if (type.name === assetType.name) {
            assetType.checked = true
            // If checked, it is available since a user already owns that asset. We do not want to disable the button for unreserving an asset.
            assetType.available = true
            assetType.comment = type.comment
            assetType.qty = type.qty
          }
        })
      })

      this.assetTypes = assetTypes
    },
    isDisabled() {
      this.disabled = this.invitedUsers.length
        ? !this.isValidInfo ||
          this.invitedUsers.some(
            invitedUser =>
              invitedUser.email && !emailRegex.test(invitedUser.email)
          )
        : false
    },
    resetInvitedUserObject() {
      this.disabled = false
      this.$emit('refresh')
    },
    isEmailValid(email) {
      if (email === '') return null

      return !emailRegex.test(email) ? 'is-invalid' : null
    },
    validateForm() {
      const validGuests = []
      this.invitedUsers.forEach(user => {
        if (user.name && user.email) {
          validGuests.push('guest')
        }
      })
      this.isValidInfo =
        validGuests.length === this.invitedUsers.length
      this.isDisabled()
    },
    addField() {
      if (!this.isAllowedToInviteGuests) {
        this.updateAlert({
          variant: 'warning',
          text: 'You are not allowed to invite guests'
        })
        return
      }
      if (
        this.invitedUsers.length >= 1 &&
        this.singleReservation.objectType === 'DESK'
      ) {
        this.updateAlert({
          variant: 'warning',
          text: 'Only one guest can be invited for desk reservation'
        })
        return
      }
      this.invitedUsers.push({ name: '', email: '' })
    },
    removeField(index) {
      this.invitedUsers.splice(index, 1)
      this.validateForm()
    },
    async updateReservation() {
      try {
        this.isBusy = true
        // Enter is pressed but button is still disabled
        if (this.disabled) return

        const { id, hash, reservationSubject, visibleToOthers } = {
          ...this.singleReservation
        }

        const checkedAssets = []

        this.assetTypes.forEach(assetType => {
          if (assetType.checked) {
            checkedAssets.push({
              name: assetType.name,
              comment: assetType.comment,
              qty: assetType.qty,
              reservationId: id,
              id: assetType.id,
              limit: assetType.limit,
              reservationCount: assetType.reservationCount,
              serviceEmail: assetType.serviceEmail
            })
          }
        })

        const errors = []
        checkedAssets.forEach(asset => {
          if (parseInt(asset.qty, 10) > asset.limit) {
            errors.push(
              `Only ${asset.limit - asset.reservationCount} ${
                asset.name
              } assets available but you asked for ${parseInt(
                asset.qty,
                10
              )}`
            )
          }
        })
        if (errors.length) {
          this.errors = errors
          return
        }
        this.errors = []
        const data = {
          id,
          hash,
          reservationSubject,
          visibleToOthers,
          reservationAssets: checkedAssets
        }
        if (reservationSubject && reservationSubject.length > 255) {
          this.updateAlert({
            variant: 'warning',
            text:
              'Reservation subject must be less than 255 characters'
          })
          return
        }

        // Get valid guests with name and email
        data.invitedUsers = this.invitedUsers.filter(
          user =>
            user.email && user.name && emailRegex.test(user.email)
        )

        const message = await OkkuApi.updateReservation(data)
        this.updateAlert({
          variant: 'success',
          text: message
        })
        this.$bvModal.hide('update-reservation-modal')
      } catch ({ response: { data } }) {
        this.updateAlert({
          variant: 'danger',
          text: data
        })
        this.$bvModal.hide('update-reservation-modal')
      } finally {
        this.isBusy = false
        this.$emit('refresh')
      }
    },
    updateAlert(args) {
      this.dismissCountDown = 3
      this.alertVariant = args.variant
      this.alertText = args.text
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    }
  }
}
</script>
