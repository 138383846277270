<template>
  <div class="page-container">
    <section class="section-settings">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-lg-12">
            <b-alert dismissible fade :variant="alertVariant" :show="dismissCountDown"
              @dismiss-count-down="countDownChanged">
              {{ alertText }}
            </b-alert>
            <div class="title-wrap">
              <div class="top-nav">
                <router-link class="link-back" :to="{
                  name: 'WorkplaceReservationPage'
                }">
                  <font-awesome-icon icon="arrow-left" />
                  {{ $t('backTo') }}
                  {{ $t('desks') }}
                </router-link>
              </div>
              <h1 class="title-1">
                {{ $t('myReservations') }}
              </h1>
            </div>
            <ReservationsTable :key="didCheckin" v-bind="{ enablePhysicalDistancingCheckinScreen }"
              @updateAlert="updateAlert" />
          </div>
        </div>
      </div>
    </section>
    <b-modal id="modal-checkIn" :title="didCheckin ? $t('checkedIn') : $t('automaticCheckInFailed')
      " hide-footer :body-text-variant="!didCheckin && 'danger'">
      <div class="d-block text-center">
        <div v-if="didCheckin" class="text-success">
          <p>
            {{ $t('automaticallyCheckedIn') }}
          </p>
          <font-awesome-icon icon="check-circle" variant="success" size="5x" />
        </div>
        <div v-else>
          <div v-if="showCheckInWithoutReservationText">
            <p>
              {{
                $t('noReservationFoundAllowBuildingCheckIn', {
                  building: buildingName
                })
              }}
            </p>
          </div>
          <div v-else>
            <p>
              {{ $t('noCurrentReservationFound') }}
            </p>
            <font-awesome-icon icon="exclamation-circle" size="5x" />
          </div>
        </div>
        <div v-if="showCheckInWithoutReservationText" class="d-block text-center">
          <b-button variant="danger" class="mt-1" @click="$bvModal.hide('modal-checkIn')">
            {{ $t('no') }}
          </b-button>
          <b-button class="mt-1 buttonConfirm" primary @click="addBuildingCheckInRecord">
            {{ $t('yes') }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import ReservationsTable from '@/components/common/ReservationsTable'
import OkkuApi from '@/services/OkkuApi'

export default {
  name: 'MyReservationsPage',
  components: {
    ReservationsTable
  },
  data() {
    return {
      dismissCountDown: 0,
      alertVariant: 'success',
      alertText: '',
      didCheckin: false,
      shouldChangeOrg: false,
      showCheckInWithoutReservationText: false,
      buildingName: '',
      enablePhysicalDistancingCheckinScreen: true
    }
  },
  computed: {
    ...mapState('common', {
      organisation: '$organisation',
      userInfo: '$userInfo'
    })
  },
  watch: {
    userInfo(now, before) {
      this.changeOrg()
    }
  },
  async mounted() {
    this.enablePhysicalDistancingCheckinScreen = this.userInfo?.groups[
      this.organisation
    ]?.enablePhysicalDistancingCheckinScreen

    const query = { ...this.$route.query }

    if (query.buildingId !== undefined && query.code !== undefined) {
      // go call /api/3.0/qr/check-in/:buildingID/:code and use the results
      // {
      //   checkin: reservations.length,
      //   checkInWithoutReservation,
      //   org: building.name,
      //   building: building.path
      // }
      const checkInResponse = await OkkuApi.checkInReservationWithQrCode(query.buildingId, query.code)
      this.didCheckin = Number.parseInt(checkInResponse.checkin, 10)
      if (checkInResponse.checkInWithoutReservation === 'true') {
        this.showCheckInWithoutReservationText = true
      } else {
        this.showCheckInWithoutReservationText = false
      }

      this.shouldChangeOrg = `/${query.name}${query.path}`
      this.buildingName = this.userInfo.groups[
        this.shouldChangeOrg
      ].orgName
      this.changeOrg()

      // Update query params
      delete query.code
      delete query.buildingId
      delete query.name
      delete query.path

      // this.$router.replace({ query })
      this.$bvModal.show('modal-checkIn')
      // 
    }
  },
  methods: {
    ...mapActions('common', ['updateOrganization']),

    async addBuildingCheckInRecord() {
      this.$bvModal.hide('modal-checkIn')

      try {
        const response = await OkkuApi.addBuildingCheckInRecord()
        if (response) {
          this.$router.push('/workplace/desks')
        }
      } catch (error) {
        this.updateAlert({
          variant: 'danger',
          text: this.$t('changesNotSaved')
        })
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    updateAlert(args) {
      this.dismissCountDown = 3
      this.alertVariant = args.variant
      this.alertText = args.text
    },
    async changeOrg() {
      if (this.shouldChangeOrg && this.userInfo) {
        const changeTo = this.shouldChangeOrg
        this.shouldChangeOrg = false

        await this.updateOrganization(changeTo)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.buttonConfirm {
  margin-left: 1rem;
}
</style>
