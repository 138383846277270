<template>
  <div>
    <div>
      <div class="filters-row mt-4" :class="isError ? 'mb-0' : ''">
        <input
          id="table-filter"
          ref="filter"
          v-model="search"
          style="width: 200px"
          type="text"
          class="form-control"
          :placeholder="$t('filterTable')"
        />

        <b-form-checkbox v-model="showRooms" class="mt-2">
          <span class="option-label">
            {{ $t('showRooms') }}
          </span>
        </b-form-checkbox>

        <b-form-checkbox v-model="showDesks" class="mt-2">
          <span class="option-label">
            {{ $t('showDesks') }}
          </span>
        </b-form-checkbox>

        <DatePickerForReservations
          v-bind="{ minDatetime }"
          place-holder="fromDate"
          :reservation-filter-date="reservationDateFrom"
          :title="dateFromHint"
          @setFilterDate="setReservationDateFrom"
          @resetFilter="resetReservationDateFrom"
        />

        <DatePickerForReservations
          v-bind="{ minDatetime }"
          place-holder="To date"
          :reservation-filter-date="reservationDateTo"
          :max-datetime="maxEndTime"
          :title="$t('filterDateToDatePickerHint')"
          @setFilterDate="setReservationDateTo"
          @resetFilter="resetReservationDateTo"
        />
      </div>
      <div style="float:right;">
        <p v-if="isError" class="text-danger">
          {{ $t('selectGreaterStartDate') }}
        </p>
      </div>
    </div>
    <div class="tabe-responsive">
      <table
        ref="table"
        class="table-style table-settings table_sort"
      >
        <thead>
          <tr>
            <th>{{ $t('building') }}</th>
            <th>{{ $t('floor') }}</th>
            <th>{{ $t('room') }}</th>
            <th>{{ $t('desk') }}</th>
            <th>{{ $t('details') }}</th>
            <th>
              {{ $t('start') }}
              <font-awesome-icon
                class="sort-icon"
                :icon="
                  sortBy === 'start_datetime'
                    ? sortAsc
                      ? 'sort-up'
                      : 'sort-down'
                    : 'sort'
                "
                @click="changeSort('start_datetime')"
              />
            </th>
            <th>
              {{ $t('end') }}
              <font-awesome-icon
                class="sort-icon"
                :icon="
                  sortBy === 'end_datetime'
                    ? sortAsc
                      ? 'sort-up'
                      : 'sort-down'
                    : 'sort'
                "
                @click="changeSort('end_datetime')"
              />
            </th>
            <th>{{ $t('checkedIn') }}</th>
            <th v-if="enablePhysicalDistancingCheckinScreen">
              {{ $t('confirmationPage') }}
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          <template v-for="(reservation, index) in reservations">
            <tr
              :key="reservation.id"
              :data-val="
                `${reservation.roomName} ${reservation.objectName}`
              "
              class="d-none d-md-table-row"
            >
              <td
                class="show-label"
                :data-label="$t('building') + ':'"
              >
                {{ reservation.buildingName }}
              </td>
              <td class="show-label" :data-label="$t('floor') + ':'">
                {{ reservation.floorName }}
              </td>
              <td class="show-label" :data-label="$t('room') + ':'">
                {{ reservation.roomName }}
              </td>
              <td class="show-label" :data-label="$t('desk') + ':'">
                {{ reservation.deskName ? reservation.deskName : '' }}
              </td>
              <td :data-label="$t('guestName') + ':'">
                <font-awesome-icon
                  v-if="reservationGuests(reservation)"
                  :id="`tooltip-guest-icon${reservation.id}`"
                  icon="user-friends"
                  class="m-1"
                />
                <font-awesome-icon
                  v-if="hasReservationAssets(reservation)"
                  :id="`tooltip-assets-${reservation.id}`"
                  icon="tools"
                  class="m-1"
                />
                <font-awesome-icon
                  v-if="reservation.reservationSubject"
                  :id="`tooltip-subject-icon${reservation.id}`"
                  class="tip-icon m-1"
                  icon="comment-alt"
                />
              </td>
              <b-tooltip
                v-if="reservationGuests(reservation)"
                :target="`tooltip-guest-icon${reservation.id}`"
                placement="bottom"
              >
                {{ $t('for') }}
                <ul class="tooltip-list-items">
                  <li
                    v-for="(guest, guestIndex) in reservation.guest"
                    :key="guestIndex"
                  >
                    {{ guest }}
                  </li>
                </ul>
              </b-tooltip>
              <b-tooltip
                v-if="hasReservationAssets(reservation)"
                :target="`tooltip-assets-${reservation.id}`"
                placement="bottom"
              >
                <ul class="tooltip-list-items">
                  <li
                    v-for="(asset,
                    assetIndex) in reservation.reservationAssets"
                    :key="assetIndex"
                  >
                    {{ asset.name }}
                  </li>
                </ul>
              </b-tooltip>
              <b-tooltip
                v-if="reservation.reservationSubject"
                :target="`tooltip-subject-icon${reservation.id}`"
                placement="bottom"
              >
                <ul class="tooltip-list-items">
                  <li>
                    {{ reservation.reservationSubject }}
                  </li>
                </ul>
              </b-tooltip>
              <td
                class="show-label"
                :data-label="$t('startsOn') + ':'"
              >
                {{
                  reservation.startDatetime
                    | moment(
                      'timezone',
                      buildingTimezone,
                      'D-MMM-YYYY H:mm'
                    )
                }}
              </td>
              <td class="show-label" :data-label="$t('endsOn') + ':'">
                {{
                  reservation.endDatetime
                    | moment('timezone', buildingTimezone, 'HH:mm')
                }}
              </td>
              <td
                class="show-label"
                :data-label="$t('checkedIn') + ':'"
              >
                {{ reservation.checkInDatetime | moment('HH:mm') }}
              </td>
              <td
                v-if="enablePhysicalDistancingCheckinScreen"
                :data-label="$t('confirmationPage') + ':'"
              >
                <b-button
                  :href="
                    '/workplace/reservation-status/' +
                      reservation.hash
                  "
                  primary
                >
                  {{ $t('viewConfirmation') }}
                </b-button>
              </td>
              <td data-label>
                <template
                  v-if="
                    isCheckedIn(reservation) === true &&
                      reservationIsCurrent(reservation) === true
                  "
                >
                  <span
                    class="link-remove"
                    @click="
                      ;(currentIndex = index),
                        $bvModal.show('modal-checkout')
                    "
                  >
                    <font-awesome-icon icon="sign-out-alt" />
                    {{ $t('checkOut') }}
                  </span>
                </template>
                <template
                  v-else-if="
                    reservation.forUser.username === userInfo.user
                  "
                >
                  <span
                    class="link-remove"
                    @click="
                      ;(currentIndex = index),
                        $bvModal.show('modalCancelReservation')
                    "
                  >
                    <font-awesome-icon icon="times" />
                    {{ $t('cancel') }}
                  </span>
                  <span
                    class="link-edit"
                    @click="
                      showEditModal(
                        reservation.id,
                        reservation.startDatetime
                      )
                    "
                  >
                    <font-awesome-icon icon="edit" />
                    {{ $t('edit') }}
                  </span>
                </template>
              </td>
            </tr>
            <tr
              :key="index"
              :data-val="
                `${reservation.roomName} ${reservation.objectName}`
              "
              class="d-sm-table-row d-md-none d-lg-none"
            >
              <td class="show-label" :data-label="$t('where') + ':'">
                <div class="row">
                  <div class="column col-9 pr-4">
                    {{ reservation.buildingName }}
                    /
                    {{ reservation.floorName }} /
                    {{ reservation.roomName }} /
                    {{
                      reservation.deskName ? reservation.deskName : ''
                    }}
                  </div>
                  <div class="column col-3 ">
                    <b-button
                      :href="
                        '/workplace/reservation-status/' +
                          reservation.hash
                      "
                      class="float-right"
                      size="sm"
                    >
                      <div>
                        <font-awesome-icon
                          icon="id-badge"
                          size="lg"
                        />
                      </div>
                      {{ $t('confirmation') }}
                    </b-button>
                  </div>
                </div>
              </td>
              <td class="show-label" :data-label="$t('when') + ':'">
                {{
                  reservation.startDatetime | moment('dd DD MMM H:mm')
                }}
                -
                {{ reservation.endDatetime | moment('HH:mm') }}
                <span
                  v-if="reservation.checkInDatetime"
                  class="float-right text-success"
                >
                  {{ $t('checkedIn') }}
                  <font-awesome-icon icon="check-circle" size="lg" />
                </span>
              </td>

              <td data-label>
                <template
                  v-if="
                    isCheckedIn(reservation) === true &&
                      reservationIsCurrent(reservation) === true
                  "
                >
                  <div
                    class="row"
                    @click="
                      ;(currentIndex = index),
                        $bvModal.show('modal-checkout')
                    "
                  >
                    <div class="col-5">
                      {{ $t('leaving') }}
                    </div>
                    <div class="col-7">
                      <b-button
                        class="float-right"
                        size="sm"
                        @click="
                          ;(currentIndex = index),
                            $bvModal.show('modal-checkout')
                        "
                      >
                        <font-awesome-icon icon="sign-out-alt" />
                        {{ $t('checkOut') }}
                      </b-button>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="row"
                    @click="
                      ;(currentIndex = index),
                        $bvModal.show('modalCancelReservation')
                    "
                  >
                    <div class="col-6 pr-0">
                      {{ $t('needToCancel') }}
                    </div>
                    <div class="col-6">
                      <b-button
                        variant="danger"
                        class="float-right"
                        size="sm"
                        @click="
                          ;(currentIndex = index),
                            $bvModal.show('modalCancelReservation')
                        "
                      >
                        <font-awesome-icon icon="times" />
                        {{ $t('cancel') }}
                      </b-button>
                    </div>
                  </div>
                </template>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <b-modal
      id="modal-checkout"
      :on-enter-key-press="() => removeReservation(currentIndex)"
      hide-footer
    >
      <template v-slot:modal-title>
        <strong>{{ $t('checkOut') }}</strong>
      </template>
      <div class="d-block text-center">
        <p>{{ $t('checkoutConfirmation') }}</p>
        <b-button
          variant="danger"
          class="mt-1"
          @click="$bvModal.hide('modal-checkout')"
        >
          {{ $t('no') }}
        </b-button>
        <b-button
          class="mt-1"
          primary
          @click="removeReservation(currentIndex)"
        >
          {{ $t('yes') }}
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="modalCancelReservation"
      :on-enter-key-press="() => removeReservation(currentIndex)"
      hide-footer
      :title="$t('cancelReservation')"
    >
      <template v-slot:modal-title>
        <strong>{{ $t('cancelReservation') }}</strong>
      </template>
      <div class="d-block text-center">
        <p>{{ $t('cancelReservationConfirmation') }}</p>
        <b-button
          variant="danger"
          class="mt-1"
          @click="$bvModal.hide('modalCancelReservation')"
        >
          {{ $t('no') }}
        </b-button>
        <b-button
          class="mt-1 buttonConfirm"
          primary
          @click="removeReservation(currentIndex)"
        >
          {{ $t('yes') }}
        </b-button>
      </div>
    </b-modal>
    <update-reservation-modal
      :single-reservation="reservation"
      :old-reservation="reservationData"
      :invited-users="invitedUsers"
      @refresh="refreshPage"
    />
  </div>
</template>
<script>
import moment from 'moment-timezone'
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import vueMoment from 'vue-moment'
import DatePickerForReservations from '@/components/common/DatePickerForReservations'
import OkkuApi from '@/services/OkkuApi'
import BootstrapModalHOC from '@/components/hocs/BootstrapModal'
import UpdateReservationModal from '@/components/modals/UpdateReservationModal'
import { encryptString } from '@/services/Helper'

Vue.use(vueMoment)

const defaultDate = moment()
  .utc()
  .startOf('day')
  .toISOString()

export default {
  name: 'ReservationsTable',
  components: {
    'b-modal': BootstrapModalHOC,
    'update-reservation-modal': UpdateReservationModal,
    DatePickerForReservations
  },
  props: {
    enablePhysicalDistancingCheckinScreen: {
      required: true,
      type: Boolean
    }
  },

  data() {
    return {
      currentIndex: null,
      maxEndTime: null,
      invitedUsers: [],
      search: '',
      sortBy: 'start_datetime',
      sortAsc: true,
      timeoutHandler: null,
      reservation: {},
      reservationData: {},
      reservationDateFrom: defaultDate,
      reservationDateTo: '',
      showRooms: true,
      showDesks: true,
      isError: false
    }
  },
  computed: {
    ...mapState('common', {
      organisation: '$organisation',
      userInfo: '$userInfo',
      settings: '$settings'
    }),
    ...mapState('common', ['desks']),
    ...mapState('reservations', {
      loadedReservations: 'reservations'
    }),
    ...mapGetters('common', {
      buildingTimezone: 'buildingTimezone'
    }),
    encryptedEmail() {
      return encryptString(this.userInfo.user)
    },

    dateFromHint() {
      return `${this.$t('filterDateFromDatePickerHint')} (${this.$t(
        'resevationAnonimysationNote'
      )} ${this.settings.anonymiseAfterDays} ${this.$t('days')})`
    },

    minDatetime() {
      return moment(defaultDate)
        .subtract(this.settings.anonymiseAfterDays, 'days')
        .toISOString()
    },

    reservations() {
      if (this.search) {
        return this.loadedReservations.filter(
          reservation =>
            reservation?.floorName
              ?.toLowerCase()
              ?.includes(this.search.toLowerCase()) ||
            reservation?.roomName
              ?.toLowerCase()
              ?.includes(this.search.toLowerCase()) ||
            reservation?.startDatetime
              ?.toLowerCase()
              ?.includes(this.search.toLowerCase())
        )
      }
      return this.loadedReservations
    }
  },
  watch: {
    organisation() {
      this.getReservations()
    },
    showRooms() {
      this.getReservations()
    },
    showDesks() {
      this.getReservations()
    }
  },

  mounted() {
    if (this.organisation) {
      this.getReservations()
    }
    this.maxEndTime = moment(defaultDate)
      .add(
        this.settings?.reservationSettings?.futureDayMaximum || 10,
        'days'
      )
      .toISOString()
  },

  methods: {
    ...mapActions('reservations', ['loadReservations']),

    resetReservationDateFrom() {
      this.reservationDateFrom = defaultDate
      this.setFilterDate()
    },

    resetReservationDateTo() {
      this.reservationDateTo = ''
      this.setFilterDate()
    },

    setReservationDateFrom(dateFrom) {
      this.reservationDateFrom = moment(dateFrom).toISOString()
      this.setFilterDate()
    },

    setReservationDateTo(dateTo) {
      this.reservationDateTo = moment(dateTo).toISOString()
      this.setFilterDate()
    },

    setFilterDate() {
      if (
        moment(this.reservationDateFrom).isAfter(
          this.reservationDateTo
        )
      ) {
        this.isError = true
        return
      }
      this.isError = false
      this.search = ''
      this.getReservations()
    },

    refreshPage() {
      if (this.organisation) {
        this.getReservations()
      }
    },
    prepareReservationData(reservationId) {
      const reservation = this.reservations.find(
        reservationData => reservationData.id === reservationId
      )
      const { guestEmails, guest } = reservation
      // Construct array from guest names and emails
      const invitedUsers = []
      for (let i = 0; i < guestEmails.length; i += 1) {
        if (guestEmails[i] && guest[i]) {
          invitedUsers.push({
            email: guestEmails[i],
            name: guest[i]
          })
        }
      }
      this.invitedUsers = invitedUsers
      this.reservation = reservation
      this.reservationData = { ...reservation }
    },

    async showEditModal(reservationId, reservationStartDate) {
      if (
        moment(reservationStartDate)
          .tz('UTC')
          .isBefore(moment().toISOString())
      ) {
        this.updateAlert(
          'warning',
          this.$t('pastReservationCanNotBeEdited')
        )
        return
      }
      await this.prepareReservationData(reservationId)
      this.$bvModal.show('update-reservation-modal')
    },

    async getReservations() {
      const fromDate = moment(this.reservationDateFrom)
        .tz('UTC')
        .startOf('day')
        .toISOString()
      const toDate = moment(this.reservationDateTo)
        .tz('UTC')
        .endOf('day')
        .toISOString()
      await this.loadReservations({
        fromDate,
        toDate,
        email: this.encryptedEmail,
        searchQuery: this.search,
        orderBy: this.sortBy,
        direction: this.sortAsc ? 'ASC' : 'DESC',
        // Convert with "+" boolean value to integer for preparation as GET parameters
        filters: {
          showRooms: +this.showRooms,
          showDesks: +this.showDesks
        }
      })

      this.reservations = this.loadedReservations
    },

    async changeSort(sortBy) {
      if (sortBy === this.sortBy) {
        this.sortAsc = !this.sortAsc
      } else {
        this.sortBy = sortBy
      }
      await this.getReservations()
    },

    updateAlert(variant, text) {
      this.$emit('updateAlert', { variant, text })
    },

    removeReservation(index) {
      if (this.reservations.length) {
        const { hash } = this.reservations[index]
        OkkuApi.cancelReservation(hash)
          .then(() => {
            this.reservations.splice(index, 1)
            this.updateAlert('success', this.$t('changesSaved'))
          })
          .catch(error => {
            let message = this.$t('failedToCancel')
            if (error.status === 400) {
              message = error.data
            }
            this.updateAlert('danger', message)
          })
        this.$bvModal.hide('modalCancelReservation')
        this.$bvModal.hide('modal-checkout')
      }
      return false
    },

    isCheckedIn(reservation) {
      return reservation.checkInDatetime != null
    },

    reservationIsCurrent(reservation) {
      const currentTime = moment()
      const reservationEnds = moment(reservation.endDatetime)
      const reservationStarts = moment(reservation.startDatetime)
      return (
        currentTime.isBefore(reservationEnds) &&
        currentTime.isAfter(reservationStarts)
      )
    },

    reservationGuests(reservation) {
      if (reservation.guest[0]) {
        return true
      }
      return false
    },

    hasReservationAssets(reservation) {
      return reservation.reservationAssets.length !== 0
    }
  }
}
</script>
<style lang="scss" scoped>
.tip-icon::v-deep {
  .fa-question-circle {
    font-size: 16px;
    margin-right: 5px;
    cursor: pointer;
  }
}

table {
  input {
    display: inline-block;
    vertical-align: middle;
  }
}
#modal-checkIn::v-deep {
  .modal-body {
    padding-top: 30px;
  }
}

#modalCancelReservation {
  .buttonConfirm {
    margin-left: 1rem;
  }
}
.svg-inline--fa {
  display: inline-block;
  font-size: 18px;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
ul.tooltip-list-items {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.table-settings.table-settings th {
  font-weight: 400;
  height: 40px;
  width: 100px;
}
@media (min-width: 761px) {
  .show-on-mobile {
    display: none !important;
  }
}
@media (max-width: 760px) {
  .show-on-desktop {
    display: none !important;
  }
}

.filters-row {
  display: flex;
  justify-content: space-between;
  @media (max-width: 760px) {
    display: block;
    > * {
      margin-bottom: 20px;
    }
  }
}
</style>
